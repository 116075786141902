.checkoutSummaryLineItemContainer {
  @apply flex flex-row w-full mb-4 leading-6;
}

.lineItemImage {
  @apply mr-4 w-16 h-16 rounded-lg;
}

.lineItemContent {
  @apply flex flex-col w-full;
}

.lineItemTitle {
  @apply leading-5 text-left flex flex-row font-semibold text-sm mb-2;
}

.lineItemProduct {
  @apply leading-5 flex flex-row w-full font-light text-spring-neutral-50 text-sm text-left;
}

.lineItemInfo {
  @apply leading-5 flex flex-row w-full justify-between;
}

.lineItemCurrency {
  @apply font-medium text-sm;
}
