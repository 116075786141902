.cartCheckoutHeaderContainer {
  @apply lg:hidden bg-white flex flex-row w-full shadow-lg h-16 items-center justify-between px-2 sticky top-[97px] z-20;
}

.arrowDownButton {
  @apply flex flex-row items-center justify-between;
}

.currencyMargin {
  @apply mr-1;
}

.icon {
  @apply w-4 h-4;
}
