.checkoutSummaryContainer {
  @apply sm:rounded-lg p-4 lg:p-0;
}

.checkoutSummaryHeader {
  @apply flex flex-row items-center justify-between pb-5;
}

.modifyOrderLink {
  @apply underline font-light text-sm;
}

.checkoutSummaryContent {
  @apply flex flex-col w-full bg-white rounded-xl lg:p-6;
}

.hrBorder {
  @apply border-gray-300;
}

.totalContainer {
  @apply flex flex-col leading-8 pt-4;
}

.totalSection {
  @apply flex flex-row justify-between;
}

.subtotalSection {
  @apply text-sm leading-7 font-medium;
}

.discountSection {
  @apply text-spring-positive font-light;
}

.totalValueSection {
  @apply text-base leading-9 font-semibold;
}

.totalSectionContent {
  @apply text-sm leading-7 font-light text-spring-neutral-50;
}
