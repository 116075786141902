.backdrop {
  @apply fixed inset-0 bg-black/30;
}

.dialogContainer {
  @apply fixed inset-0 flex m-auto items-center justify-center z-30;
}

.dialogPanel {
  @apply relative bg-white p-10 w-full md:w-3/4 max-w-[500px] min-h-[200px] rounded-lg shadow-md;
}

.closeButton {
  @apply absolute right-2 top-2;
}

.dialogTitle {
  @apply text-3xl mb-4;
}

button.verifyButton {
  @apply bg-primary-button-background border border-primary-button-border p-4 mt-2 rounded-lg flex space-x-1 text-sm font-medium text-primary-text-color hover:text-gray-400;
}

.continueButton {
  @apply p-4 mt-2 mr-2 rounded-lg flex space-x-1 text-sm font-medium hover:text-gray-400;
}
