.quantitySelectorContainer {
  @apply relative rounded-lg p-1 border h-[50px];
}

.quantity-label {
  @apply absolute bg-white left-5 translate-y-[-8px] text-xs text-slate-950;
}

.quantityContainer {
  @apply flex justify-center content-center relative rounded-md h-[50px] mt-3;
}

.quantityInput {
  @apply outline-none text-spring-neutral-50 block w-full border-spring-light-gray rounded-md text-base leading-7 font-light focus:outline-none focus:text-spring-neutral-100 focus:border-spring-neutral-100 focus:ring-0 transition-all duration-200 ease-linear;
}
