.paymentSeparator {
  @apply relative flex py-4 items-center;
}

.paymentSeparatorLine {
  @apply flex-grow border-t border-spring-neutral-20;
}

.paymentSeparatorText {
  @apply flex-shrink mx-4;
}
