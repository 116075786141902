.haveAPromoCodeText {
  @apply font-medium;
}

button.haveAPromoCodeButton {
  @apply mt-4 w-full h-12 flex rounded-md items-center px-3.5 border text-spring-neutral-70 border-spring-neutral-20 bg-white;
}

.haveAPromoCodeContainer {
  @apply w-full px-6 md:px-0 lg:hidden pt-4;
}
