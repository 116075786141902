.inputBase {
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}

.inputBase:focus {
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%),
    0 1px 1px 0 rgba(0, 0, 0, 0.08);
}

.labelBase {
  margin-bottom: 5px;
  font-size: 0.93rem;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.inlineButton {
  @apply absolute text-sm inset-y-0 right-0 flex items-center pr-3 cursor-pointer text-spring-blue;
}

.warningText,
.errorText {
  @apply text-sm text-left text-spring-warning-dark;
}

.warningText {
  @apply text-spring-warning-dark;
}

.errorText {
  @apply text-spring-negative;
}
